import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@material-ui/core";
import React from "react";
import useStyles from "./BlogInfoStyles";
import Blog1 from "../../assets/React-to-aws.svg";


const BlogInfo = (props: any) => {
  const classes = useStyles();
  return (
    <div id="blog" className={classes.blogsInfoMainDiv}>
      <h2 className={classes.blogsHeader}>BLOG</h2>
      <Grid container className={classes.root}>
        {/* <Grid item xs={12} sm={6}></Grid> */}
        <div className={classes.cardGridRoot}>
          <Card
            className={classes.cardRoot}
            onClick={() =>
              window.open(
                "https://sivakumarreddypatil.blogspot.com/2021/07/deploy-react-app-using-express-server.html"
              )
            }
          >
             <CardMedia
                className={classes.media}
                image={Blog1}
                title="Paella dish"
              />
            <CardContent style={{ paddingBottom: 0 }}>
              <Typography
                className={classes.cardTitle}
                color="textSecondary"
                gutterBottom
              >
                #Reactjs, #aws, #express, #node
              </Typography>
              <Typography
                variant="h5"
                component="h2"
                style={{ marginTop: 8, marginBottom: 8 }}
              >
                Deploy React app with express server in AWS EC2
              </Typography>

              <Typography
                variant="body2"
                component="p"
                className={classes.showBlogParagraphElipse}
              >
                After completion of development the next question which comes in
                mind would be where/how should we host/deploy the application.
                In the current world we see lot many options to host application
                and one among them is EC2 instance. In todays blog we would
                discuss about this.
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" className={classes.readFullButton}>
                Read Full
              </Button>
            </CardActions>
          </Card>
        </div>
      </Grid>
      <div className={classes.blogsBackground}></div>
    </div>
  );
};

export default BlogInfo;
