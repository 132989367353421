import React from "react";
import useStyles from "./AboutInfoStyles";

const AboutInfo = () => {
  const classes = useStyles();
  return (
    <div id="about" className={classes.aboutInfoMainDiv}>
      <h2 className={classes.skillsHeader}>ABOUT ME</h2>
      <div className={classes.aboutContent}>
        <div className={classes.title}>Nice to meet you.</div>
        <h1 className={classes.titleHeading}>
          Hey, I'm Siva Kumar Reddy,
        </h1>
        <div>
          <p className={classes.paragraph}>
            Full stack developer from Bangalore, India with over 7
            years experience in leading teams and shipping complex digital
            products. Specailized in ui development using various leading
            technologies like React, React-Native, Angular etc.
            <br />
            <br /> My passion is Information Technology and it was my natural
            choice to choose this as my career. In my free time I would be
            exploring new technologies.
          </p>
        </div>
      </div>
      <div className={classes.aboutBackground}></div>
    </div>
  );
};

export default AboutInfo;
