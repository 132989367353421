import { Grid, Slide } from "@material-ui/core";
import React from "react";
import Typed from "react-typed";
import "./IntroInfo.scss";
import useStyles from "./IntroInfoStyles";

interface IntroInfoProps {
  hideBrandName: boolean;
}

const IntroInfo = (props: IntroInfoProps) => {
  const classes = useStyles();
  return (
    <div id="home" className="intro route bg-image background">
      <div id="stars" />
      <div id="stars2" />
      <div id="stars3" />

      <Grid item xs={12} className={classes.introGrid}>
        <Grid item xs={12} className={classes.brandNameGrid}>
          <div id="name">
            <Slide
              direction="down"
              in={props.hideBrandName}
              mountOnEnter
              unmountOnExit
              style={{
                transitionDelay: props.hideBrandName ? "1000ms" : "0ms",
              }}
            >
              <div
                className={classes.brand}
                style={{ display: props.hideBrandName ? "block" : "none" }}
              >
                <h1 className={classes.title}>Hello, I'm Siva Kumar Reddy</h1>
                <p className={classes.subtitle}>
                  <Typed
                    strings={[
                      "Senior Software Engineer",
                      "Certified Scrum Master (scrum.org)",
                      "Full Stack Developer",
                      "UI Developer",
                    ]}
                    typeSpeed={100}
                    backDelay={1100}
                    backSpeed={50}
                    loop
                  />
                </p>
              </div>
            </Slide>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default IntroInfo;
