import { Grid, Link } from "@material-ui/core";
import React from "react";
import useStyles from "./ContactInfoStyles";
import EmailIcon from "@material-ui/icons/Email";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import FacebookIcon from "@material-ui/icons/Facebook";

const ContactInfo = () => {
  const classes = useStyles();

  return (
    <div id="contact" className={classes.contactInfoMainDiv}>
      <div className={classes.contactBackground}>
        <h2 className={classes.contactHeader}>CONTACT ME</h2>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} sm={6}>
            <h3 className={classes.contactHeading3}>
              If Not Now, When? 
              {/* <br /> Let’s Work Together!
              <br /> */}
              {/* <span style={{ fontSize: "1rem" }}>
                To make a better product.
              </span> */}
            </h3>
            <p className={classes.contactPara}>
              Let's collaborate to create a better product that creates best value.
            </p>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid
              container
              direction="row"
              alignItems="center"
              spacing={5}
              className={classes.contactDetails}
            >
              <Grid item xs={1}>
                <EmailIcon
                  color="inherit"
                  fontSize="large"
                  className={classes.socailLinkIcons}
                />
              </Grid>
              <Grid item xs={6}>
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => {
                    window.open("mailto:sivapatil6@gmail.com", "_blank");
                  }}
                >
                  sivapatil6@gmail.com
                </Link>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              alignItems="center"
              spacing={5}
              className={classes.contactDetails}
            >
              <Grid item xs={1}>
                <LinkedInIcon
                  color="inherit"
                  fontSize="large"
                  className={classes.socailLinkIcons}
                />
              </Grid>
              <Grid item xs={6}>
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => {
                    window.open(
                      "https://www.linkedin.com/in/siva-kumar-reddy-patil-8b120780",
                      "_blank"
                    );
                  }}
                >
                  LinkedIn
                </Link>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              alignItems="center"
              spacing={5}
              className={classes.contactDetails}
            >
              <Grid item xs={1}>
                <FacebookIcon
                  color="inherit"
                  fontSize="large"
                  className={classes.socailLinkIcons}
                />
              </Grid>
              <Grid item xs={6}>
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => {
                    window.open(
                      "https://www.facebook.com/sivapatil6",
                      "_blank"
                    );
                  }}
                >
                  Facebook
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ContactInfo;
