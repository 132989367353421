import React, { CSSProperties } from "react";
import CSS from "../../assets/css.svg";
import Angular from "../../assets/angular.svg";
import Html from "../../assets/html5.svg";
import Javascript from "../../assets/javascript.svg";
import Jquery from "../../assets/jquery.svg";
import Reactjs from "../../assets/react-native.svg";
import ReduxL from "../../assets/redux.svg";
import CSharp from "../../assets/csharp.svg";
import Typescript from "../../assets/typescript.svg";
import Git from "../../assets/git.svg";
import GitHub from "../../assets/github.svg";
import Bitbucket from "../../assets/bitbucket.svg";
import Appcenter from "../../assets/microsoft.svg";
import Sql from "../../assets/sql.svg";
import Docker from "../../assets/docker.svg";

interface ISvgIconProps {
  name: string;
  customeStyle?: CSSProperties;
}

const SvgIcon = (props: ISvgIconProps) => {
  switch (props.name) {
    case "css":
      return <img src={CSS} style={props.customeStyle || {}} alt="Css" />;
    case "angular":
      return (
        <img src={Angular} alt="angular" style={props.customeStyle || {}} />
      );
    case "html":
      return <img src={Html} alt="html" style={props.customeStyle || {}} />;
    case "javascript":
      return (
        <img
          src={Javascript}
          alt="javascript"
          style={props.customeStyle || {}}
        />
      );
    case "jquery":
      return (
        <img
          src={Jquery}
          alt="jquery"
          style={props.customeStyle || {}}
        />
      );
    case "reactjs":
      return <img src={Reactjs} alt="react" style={props.customeStyle || {}} />;
    case "redux":
      return <img src={ReduxL} alt="Redux" style={props.customeStyle || {}} />;
    case "csharp":
      return <img src={CSharp} alt="csharp" style={props.customeStyle || {}} />;
    case "sql":
      return <img src={Sql} alt="sql" style={props.customeStyle || {}} />;
    case "docker":
      return <img src={Docker} alt="Docker" style={props.customeStyle || {}} />;
    case "typescript":
      return (
        <img
          src={Typescript}
          alt="typescript"
          style={props.customeStyle || {}}
        />
      );
    case "git":
      return <img src={Git} alt="git" style={props.customeStyle || {}} />;
    case "github":
      return <img src={GitHub} alt="github" style={props.customeStyle || {}} />;
    case "bitbucket":
      return (
        <img src={Bitbucket} alt="bitbucket" style={props.customeStyle || {}} />
      );
    case "appcenter":
      return (
        <img src={Appcenter} alt="appcenter" style={props.customeStyle || {}} />
      );
    default:
      return <div></div>;
  }
};

export default SvgIcon;
