import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2rem",
    paddingTop: 0,
    //backgroundColor: "#F2F2F2",
  },
  cardRoot: {
    borderRadius: "1rem",
    maxWidth: "375px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  media: {
    height: 0,
    paddingTop: '40%'//'56.25%', // 16:9
  },
  cardGridRoot: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1),
      //   width: theme.spacing(16),
      //   height: theme.spacing(16),
    },
  },
  cardTitle: {
    color: "#55527c",
    letterSpacing: "0.03em",
    fontSize: 14,
    marginBottom: 8,
  },
  readFullButton: {
    color: "#55527c",
    letterSpacing: "0.03em",
    paddingLeft: 8,
  },
  blogsHeader: {
    fontSize: "1.5rem",
    fontWeight: 700,
    color: "#BF7E06",
    textAlign: "center",
    letterSpacing: "2px",
  },
  showBlogParagraphElipse: {
    color: "#55527c",
    letterSpacing: "0.03em",
    maxHeight: "2.5rem",
    overflow: "auto",
    textOverflow: "ellipsis",
    whiteSpace: "pre-line",
    display: "-webkit-box",
    "-webkit-line-clamp": "2",
    "-webkit-box-orient": "vertical",
  },
  blogsInfoMainDiv: {
    position: "relative",
    marginTop: "1rem",
    marginBottom: "1rem",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  button: {
    margin: theme.spacing(1),
  },
  blogsBackground: {
    position: "absolute",
    left: "0%",
    top: "auto",
    right: "auto",
    bottom: "auto",
    zIndex: -1,
    width: "70%",
    minHeight: "100%",
    borderTopRightRadius: "55px",
    borderBottomRightRadius: "55px",
    backgroundColor: "#FFFAE9",
  },
}));
export default useStyles;
