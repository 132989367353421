import {
  Avatar,
  Divider,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemText,
  Slide,
  useMediaQuery,
} from "@material-ui/core";
import React, { useState } from "react";
import clsx from "clsx";
import MenuIcon from "@material-ui/icons/Menu";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import useStyles from "./AppHeaderStyles";
import SivaLogo from "../../assets/sivaLogo.jpeg";

interface AppHeaderProps {
  isProfileNameVisible: boolean;
  isBackgroundForHeader: boolean;
  selectedTab: string;
  onNavigationClick(navigation: string): any;
}
const AppHeader = (props: AppHeaderProps) => {
  const classes = useStyles();
  const menuBreak = useMediaQuery("(min-width:950px)");
  const socialBreak = useMediaQuery("(min-width:600px)");
  const [openDrawer, setDrawer] = useState(false);

  const toggleDrawer = (anchor: any, open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setDrawer(open);
  };
  const list = (anchor: any) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List style={{}}>
        <ListItem
          button
          selected={props.selectedTab === "about"}
          classes={{ selected: classes.listItemSelected }}
          onClick={() => props.onNavigationClick("about")}
        >
          <ListItemText primary={"About Me"} style={{ color: "#FFF" }} />
        </ListItem>
        <ListItem
          button
          selected={props.selectedTab === "skill"}
          classes={{ selected: classes.listItemSelected }}
          onClick={() => props.onNavigationClick("skill")}
        >
          <ListItemText primary={"Skills"} style={{ color: "#FFF" }} />
        </ListItem>
        <ListItem
          button
          selected={props.selectedTab === "blog"}
          classes={{ selected: classes.listItemSelected }}
          onClick={() => props.onNavigationClick("blog")}
        >
          <ListItemText primary={"Blog"} style={{ color: "#FFF" }} />
        </ListItem>

        <ListItem
          button
          selected={props.selectedTab === "contact"}
          classes={{ selected: classes.listItemSelected }}
          onClick={() => props.onNavigationClick("contact")}
        >
          <ListItemText primary={"Contact"} style={{ color: "#FFF" }} />
        </ListItem>
      </List>
      {!socialBreak && (
        <>
          <Divider style={{ backgroundColor: "#bbb" }} />
          <List>
            <ListItem button>
              <ListItemText primary={"Download CV"} style={{ color: "#FFF" }} />
              <CloudDownloadIcon
                color="inherit"
                fontSize="default"
                className={classes.socailLinkIcons}
              />
            </ListItem>
          </List>
        </>
      )}
    </div>
  );
  return (
    <div
      className={clsx(classes.root)}
      style={
        props.isBackgroundForHeader
          ? {
              background:
                "radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%)",
              zIndex: 6,
            }
          : {}
      }
    >
      <Grid container className={classes.headerView}>
        <Grid item xs={10} sm={7} md={5} className={classes.logoView}>
          <Avatar
            alt="Siva Kumar Reddy"
            src={SivaLogo}
            className={classes.large}
          />
          <Slide
            direction="down"
            in={props.isProfileNameVisible}
            mountOnEnter
            unmountOnExit
            style={{
              transitionDelay: props.isProfileNameVisible ? "100ms" : "0ms",
            }}
          >
            <div style={{ marginLeft: 8, flexDirection: "column" }}>
              <h3 style={{ color: "#FFF", fontSize: "1.5rem", margin: 8 }}>
                Patil Siva Kumar Reddy
              </h3>
              <p
                style={{
                  color: "#FFF",
                  fontSize: "1rem",
                  margin: 8,
                  opacity: 0.7,
                }}
              >
                Full Stack Developer
              </p>
            </div>
          </Slide>
        </Grid>
        <Grid item xs={2} sm={5} md={7} className={classes.socialLinksView}>
          {menuBreak && (
            <div className={classes.desktopHeaderNavigation}>
              <div
                className={classes.desktopEachHeaderNavigator}
                style={
                  props.selectedTab === "about"
                    ? { borderBottom: "0.5px solid #BF7E06" }
                    : {}
                }
              >
                <span onClick={() => props.onNavigationClick("about")}>
                  About
                </span>
              </div>
              <span className={classes.divider}></span>
              <div
                className={classes.desktopEachHeaderNavigator}
                style={
                  props.selectedTab === "skill"
                    ? { borderBottom: "0.5px solid #BF7E06" }
                    : {}
                }
              >
                <span onClick={() => props.onNavigationClick("skill")}>
                  Skills
                </span>
              </div>
              <span className={classes.divider}></span>
              <div
                className={classes.desktopEachHeaderNavigator}
                style={
                  props.selectedTab === "blog"
                    ? { borderBottom: "0.5px solid #BF7E06" }
                    : {}
                }
              >
                <span onClick={() => props.onNavigationClick("blog")}>
                  Blogs
                </span>
              </div>
              <span className={classes.divider}></span>

              <div
                className={classes.desktopEachHeaderNavigator}
                style={
                  props.selectedTab === "contact"
                    ? { borderBottom: "0.5px solid #BF7E06" }
                    : {}
                }
              >
                <span onClick={() => props.onNavigationClick("contact")}>
                  Contact
                </span>
              </div>
            </div>
          )}
          {socialBreak && (
            <>
              <div className={classes.downloadCvView}>
                <span style={{ color: "#FFF" }}>Download CV</span>
                <CloudDownloadIcon
                  color="inherit"
                  fontSize="default"
                  className={classes.socailLinkIcons}
                />
              </div>
            </>
          )}
          {!menuBreak && (
            <MenuIcon
              color="inherit"
              fontSize="large"
              className={classes.socailLinkIcons}
              onClick={toggleDrawer("right", true)}
            />
          )}
          <Drawer
            classes={{ paper: classes.paper }}
            anchor={"right"}
            open={openDrawer}
            onClose={toggleDrawer("right", false)}
          >
            {list("right")}
          </Drawer>
        </Grid>
      </Grid>
    </div>
  );
};

export default AppHeader;
