import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  contactInfoMainDiv: {
    // position: "relative",
    marginTop: "1rem",
    marginBottom: "1rem",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  contactHeader: {
    fontSize: "1.5rem",
    fontWeight: 700,
    color: "#BF7E06",
    textAlign: "center",
    letterSpacing: "2px",
  },
  contactBackground: {
    padding: "1rem",
    width: "90%",
    height: "100%",
    borderRadius: "2rem",
    backgroundColor: "#e9f9ff",
  },
  contactHeading3: {
    fontSize: "2rem",
    display: "block",
    color: "#1b2735",
    fontWeight: 600,
    marginBottom: "2rem",
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  contactPara: {
    fontSize: "1rem",
    display: "block",
    color: "#1b2735",
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  contactDetails: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  socailLinkIcons: {
    // margin: "0 1rem 0 1rem",
    color: "#1b2735",
  },
}));
export default useStyles;
