import { Grid } from "@material-ui/core";
import React from "react";
import useStyles from "./FooterInfoStyles";

const FooterInfo = () => {
  const classes = useStyles();
  return (
    <div className={classes.footerMainDiv}>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={6}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <span style={{ fontWeight: 600 }}>
            ©️ {new Date().getFullYear()} Siva Kumar Reddy Patil
          </span>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <span>
            Developed by <b>Siva Kumar Reddy Patil </b>
          </span>
        </Grid>
      </Grid>
    </div>
  );
};

export default FooterInfo;
