import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2rem",
    paddingTop: 0,
    //backgroundColor: "#F2F2F2",
  },
  skillsHeader: {
    fontSize: "1.5rem",
    fontWeight: 700,
    color: "#BF7E06",
    textAlign: "center",
    letterSpacing: "2px",
  },
  skillInfoMainDiv: {
    position: "relative",
    marginTop: "1rem",
    marginBottom: "1rem",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  button: {
    margin: theme.spacing(1),
  },
  skillsBackground: {
    position: "absolute",
    left: "auto",
    top: "0%",
    right: "0%",
    bottom: "auto",
    zIndex: -1,
    width: "70%",
    minHeight: "100%",
    borderTopLeftRadius: "55px",
    borderBottomLeftRadius: "55px",
    backgroundColor: "#fff5f6",
  },
}));
export default useStyles;
