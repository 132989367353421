import React from "react";
import SvgIcon from "../svgIcon/SvgIcon";

interface ISkillProps {
  name: string;
  color?: string;
  key?: any;
  iconName?: any;
}

const Skill = (props: ISkillProps) => {
  return (
    <div
      style={{
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: props.color || "#bababa",
        borderRadius: "0.5rem",
        padding: "0.5rem 1rem",
        color: "#000",
        display: "inline-grid",
        margin: 4,
      }}
    >{props.iconName && 
      <SvgIcon
        name={props.iconName}
        customeStyle={{ marginLeft: "auto", marginRight: "auto" }}
      />
    }
      <span style={{ textAlign: "center" }}>{props.name}</span>
    </div>
  );
};

export default Skill;
