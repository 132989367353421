import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import AboutInfo from "../components/aboutInfo/AboutInfo";
import AppHeader from "../components/appHeader/AppHeader";
import BlogInfo from "../components/blogInfo/BlogInfo";
import ContactInfo from "../components/contactInfo/ContantInfo";
import FooterInfo from "../components/footerInfo/FooterInfo";
import IntroInfo from "../components/introInfo/IntroInfo";
import SkillsInfo from "../components/skillsInfo/SkillsInfo";
import useStyles from "./LandingPageStyles";

const LandingPage = () => {
  const classes = useStyles();
  const [showBrandName, setBrandNameVisibility] = useState(true);
  const [backgroundForHeader, setBackgroundForHeader] = useState(false);
  const [selectedTab, setSelectedTab] = useState("");
  useEffect(() => {
    window.addEventListener("scroll", onScroll, true);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  const onScroll = (e: any) => {
    const namePostion: any = document
      ?.getElementById("name")
      ?.getBoundingClientRect();
    if (namePostion?.y < 75) {
      //console.log(namePostion);
      setBrandNameVisibility(false);
    } else {
      setBrandNameVisibility(true);
    }
    const skillPosition: any = document
      ?.getElementById("skill")
      ?.getBoundingClientRect();
    const blogPosition: any = document
      ?.getElementById("blog")
      ?.getBoundingClientRect();
    const aboutPosition: any = document
      ?.getElementById("about")
      ?.getBoundingClientRect();
    const contactPosition: any = document
      ?.getElementById("contact")
      ?.getBoundingClientRect();
    if (aboutPosition?.y < 160) {
      setBackgroundForHeader(true);
    } else {
      setBackgroundForHeader(false);
    }
    if (aboutPosition?.y < 400 && skillPosition?.y > 400) {
      setSelectedTab("about");
    } else if (skillPosition?.y < 400 && blogPosition?.y > 401) {
      setSelectedTab("skill");
    } else if (blogPosition?.y < 401 && contactPosition?.y > 402) {
      setSelectedTab("blog");
    } else if (contactPosition?.y < 400) {
      setSelectedTab("contact");
    } else {
      setSelectedTab("");
    }
  };

  const onHeaderNavigationClick = (navigation: any) => {
    const elementInfo = document.getElementById(navigation);
    elementInfo?.scrollIntoView({ behavior: "smooth" });
    setSelectedTab(navigation);
  };

  return (
    <Grid container>
      <Grid item className={classes.containerGrid}>
        <AppHeader
          isProfileNameVisible={!showBrandName}
          isBackgroundForHeader={backgroundForHeader}
          selectedTab={selectedTab}
          onNavigationClick={onHeaderNavigationClick}
        />
        <IntroInfo hideBrandName={showBrandName} />
        <AboutInfo />
        <SkillsInfo />
        <BlogInfo />
        <ContactInfo />
        <FooterInfo />
      </Grid>
    </Grid>
  );
};

export default LandingPage;
