import { Grid, Paper, Tab, Tabs } from "@material-ui/core";
import React from "react";
import Skill from "../skill/Skill";
import useStyles from "./SkillsInfoStyles";
// import SkillsIcon from "../../assets/skills.jpg";

const SkillsInfo = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const skillsList = [
    { skill: "ReactJs", color: "", iconName: "reactjs", tab: 0 },
    { skill: "React Native", color: "", iconName: "reactjs", tab: 0 },
    { skill: "Redux", color: "", iconName: "redux", tab: 0 },
    { skill: "Angularjs / Angular", color: "", iconName: "angular", tab: 0 },
    { skill: "Javascript", color: "", iconName: "javascript", tab: 0 },
    { skill: "JQuery", color: "", iconName: "jquery", tab: 0 },
    { skill: "Typescript", color: "", iconName: "typescript", tab: 0 },
    { skill: "Html", color: "", iconName: "html", tab: 0 },
    { skill: "css", color: "", iconName: "css", tab: 0 },
    { skill: "SQL Server", color: "", iconName: "sql", tab: 0 },
    { skill: "c#", color: "", iconName: "csharp", tab: 0 },
    { skill: ".Net/ .Net Core MVC", color: "", iconName: "csharp", tab: 0 },
    { skill: "WebApi", color: "", iconName: "", tab: 0 },
    { skill: "Jasmine framework", color: "", iconName: "", tab: 0 },
    { skill: "Jest / Enzyme", color: "", iconName: "", tab: 0 },
    { skill: "Karma", color: "", iconName: "", tab: 0 },
    // { skill: "Devops", color: "", iconName: "noicon", tab: 1 },
    { skill: "App Center", color: "", iconName: "appcenter", tab: 1 },
    { skill: "Git", color: "", iconName: "git", tab: 1 },
    { skill: "Github", color: "", iconName: "github", tab: 1 },
    { skill: "Bitbucket", color: "", iconName: "bitbucket", tab: 1 },
    { skill: "Docker/Containers", color: "", iconName: "docker", tab: 1 },
  ];
  return (
    <div id="skill" className={classes.skillInfoMainDiv}>
      <h2 className={classes.skillsHeader}>SKILLS</h2>

      <Grid container className={classes.root}>
        <Grid item xs={12} sm={6}>
          <Paper square>
            <Tabs
              value={value}
              style={{ marginBottom: "0.5rem" }}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              onChange={(e: any, newValue: any) => setValue(newValue)}
              aria-label="disabled tabs example"
            >
              <Tab label="Programming" />
              <Tab label="Tools" />
            </Tabs>
          </Paper>
          {skillsList.map((x: any, index: any) => {
            return x.tab === value ? (
              <Skill
                name={x.skill}
                key={index.toString()}
                iconName={x.iconName}
              />
            ) : (
              <div key={index.toString()}> </div>
            );
          })}
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* <img
            src={SkillsIcon}
            alt="Skills"
            style={{
              height: 300,
              width: 300,
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              borderRadius: 150,
            }}
          /> */}
        </Grid>
      </Grid>
      <div className={classes.skillsBackground}></div>
    </div>
  );
};

export default SkillsInfo;
