import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    top: 0,
    width: "100%",
    display: "flex",
    "& > *": {
      padding: theme.spacing(1),
    },
  },
  headerView: {
    padding: "1rem",
    display: "flex",
    flexDirection: "row",
    //justifyContent: "space-between",
    alignItems: "center",
  },
  logoView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: "1rem",
  },
  socialLinksView: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  socailLinkIcons: {
    margin: "0 0 0 0.5rem",
    color: "#FFF",
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  paper: {
    background: "#222C40",
  },
  desktopHeaderNavigation: {
    display: "flex",
    flexDirection: "row",
    color: "#FFF",
    borderRadius: "1rem",
    padding: "0.5rem",
    background: "rgba(149, 161, 255, 0.2)",
    backdropFilter: "blur( 1rem )",
    //boxShadow: "0 8px 32px 0 rgba(31,38,135,.37)",
  },
  desktopEachHeaderNavigator: {
    padding: "0.5rem 0.75rem",
    marginLeft: 8,
    marginRight: 8,
    alignSelf: "center",
    "&:hover": {
      backgroundColor: "#090a0f",
      cursor: "pointer",
      borderRadius: "1rem",
    },
  },
  divider: {
    borderLeft: "0.25px solid #616161",
  },
  downloadCvView: {
    border: "0.5px solid #616161",
    borderRadius: "1rem",
    padding: 8,
    paddingTop: "0.75rem",
    paddingBottom: "0.75rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: 8,
  },
  listItemSelected: {
    background: "#BF7E06 !important",
    //borderBottom: "0.5px solid #BF7E06",
  },
}));
export default useStyles;
