import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2rem",
    //backgroundColor: "#F2F2F2",
  },
  aboutInfoMainDiv: {
    position: "relative",
    marginTop: "1rem",
    marginBottom: "1rem",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  skillsHeader: {
    fontSize: "1.5rem",
    fontWeight: 700,
    color: "#BF7E06",
    textAlign: "center",
    letterSpacing: "2px",
  },
  aboutBackground: {
    position: "absolute",
    left: "0%",
    top: "auto",
    right: "auto",
    bottom: "auto",
    zIndex: -1,
    width: "70%",
    height: "100%",
    minHeight: "100%",
    borderTopRightRadius: "55px",
    borderBottomRightRadius: "55px",
    backgroundColor: "#f8f8ff",
  },
  aboutContent: {
    marginRight: "auto",
    padding: "2rem",
  },
  title: {
    marginBottom: "1.5rem",
    color: "#b4b2c5",
    fontSize: "1rem",
    fontWeight: 700,
    letterSpacing: "2px",
    textTransform: "uppercase",
  },
  titleHeading: {
    maxWidth: "80%",
    fontSize: "2rem",
  },
  paragraph: {
    color: "#55527c",
    fontSize: "1.2rem",
    letterSpacing: "0.03em",
    // fontFamily: "Karla, sans-serif",
  },
}));
export default useStyles;
