import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  introGrid: {
    margin: "1rem",
    display: "flex",
  },
  brandNameGrid: {
    position: "absolute",
    top: "30%",
  },
  brand: {
    color: "#FFFFFF",
    textAlign: "left",
    margin: "1rem",
  },
  title: {
    fontSize: "3rem",
    fontWeight: 600,
    display: "inline-block",
    position: "relative",
  },
  subtitle: {
    fontSize: "1.5rem",
    fontWeight: 500,
    margin: "0 0 0",
  },
}));
export default useStyles;
